import { Timestamp } from "@bufbuild/protobuf";
import { createFileRoute } from "@tanstack/react-router";
import { format, parse, startOfMonth, subMonths } from "date-fns";
import { ja } from "date-fns/locale";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  type TransactionGroup,
  Transaction_Type,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../styled-system/css";
import Id from "../../components/id";
import { formatNumber, getTenantSlug } from "../../libs/utils";

const REDEMPTION_TIME_INTERVAL_MS =
  import.meta.env.VITE_APP_ENV === "prod-live"
    ? 60 * 60 * 1000 // 1時間
    : 31 * 24 * 60 * 60 * 1000; // 31日

export const Route = createFileRoute("/company/redemptions")({
  loader: async ({ context: { client, stores } }) => {
    return { client, stores };
  },
  component: RouteComponent,
});

type Redemption = {
  id: string;
  amount: number;
  dateText: string;
  storeId: string;
  storeName: string;
};

type RedemptionTableData = {
  redemptionId: string;
  dateText: string;
  storeId: string;
  storeName: string;
  amount: number;
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function RouteComponent() {
  const { client, stores } = Route.useLoaderData();
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const totalStores = stores.length;
  const [selectedDate, setSelectedDate] = useState(() => {
    return startOfMonth(new Date());
  });
  const [transactionGroups, setTransactionGroups] = useState<
    TransactionGroup[]
  >([]);
  const abortControllerRef = useRef<AbortController>();

  // 過去6ヶ月分の選択肢を生成
  const monthOptions = Array.from({ length: 6 }, (_, i) => {
    const date = subMonths(new Date(), i);
    const startDate = startOfMonth(date);
    return {
      value: format(startDate, "yyyy/MM"),
      label: format(startDate, "yyyy年M月", { locale: ja }),
    };
  });

  // startDateからREDEMPTION_TIME_INTERVAL_MSまでのデータを取得
  // paginationの基準日はtype = 'redeem' のtransactionのcreatedAt
  // 通常は月初から1時間以内にはデータが収まる
  // getRedemptionsの取得コストが低下した際に、基準期間の範囲を広くするなどの根本改善を行う
  const fetchRedemptions = useCallback(
    async (startDate: Date) => {
      // 既存のAbortControllerがあればキャンセルする
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // 新しいAbortControllerを作成し、ローカル変数に保持する
      const currentController = new AbortController();
      abortControllerRef.current = currentController;

      setLoading(true);
      setLoadingProgress(0);
      setTransactionGroups([]);

      // tenantSlugを取得
      const tenantSlug = getTenantSlug();

      // 日付の設定
      let limitDate: Date;
      let offsetDate: Date;

      // startDateが3月かつtenantSlugがmiyagiの場合、特定の日時を設定
      // redemptionsの処理が3時53分頃にずれているため
      if (startDate.getMonth() === 2 && tenantSlug === "miyagi") {
        // 0-indexed なので3月は2
        // JSTで2025-03-01 03:50 を設定
        limitDate = new Date("2025-03-01T03:50:00+09:00");
        // JSTで2025-03-01 04:00 を設定
        offsetDate = new Date("2025-03-01T04:00:00+09:00");
      } else {
        // 通常の計算方法
        limitDate = startDate;
        offsetDate = new Date(
          startDate.getTime() + REDEMPTION_TIME_INTERVAL_MS,
        );
      }

      const allTransactionGroups: TransactionGroup[] = [];

      try {
        for (let i = 0; i < stores.length; i++) {
          // 現在のfetchでない場合は処理を中断
          if (abortControllerRef.current !== currentController) {
            return;
          }
          if (currentController.signal.aborted) {
            return;
          }

          const store = stores[i];
          const response = await client.getRedemptions({
            storeId: store.id,
            pagination: {
              limit: Timestamp.fromDate(limitDate),
              offset: Timestamp.fromDate(offsetDate),
            },
          });

          if (response.transactionGroups.length > 0) {
            allTransactionGroups.push(...response.transactionGroups);
          }

          // 更新前にも現在のfetchかどうかチェック
          if (abortControllerRef.current !== currentController) {
            return;
          }
          setLoadingProgress(i + 1);
          await sleep(200);
        }
        if (abortControllerRef.current !== currentController) {
          return;
        }
        setTransactionGroups(allTransactionGroups);
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          console.log("Fetch operation was aborted");
        } else {
          throw error;
        }
      } finally {
        if (abortControllerRef.current === currentController) {
          setLoading(false);
        }
      }
    },
    [client, stores],
  );

  const handleDateChange = (value: string) => {
    const date = parse(value, "yyyy/MM", new Date());
    setSelectedDate(startOfMonth(date));
  };

  useEffect(() => {
    fetchRedemptions(selectedDate);

    return () => {
      // コンポーネントのクリーンアップ時にAbortControllerをキャンセル
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [selectedDate, fetchRedemptions]);

  const redemptions = useMemo((): Redemption[] => {
    const result: Redemption[] = [];

    for (const transactionGroup of transactionGroups) {
      const details = transactionGroup.transactionDetails;

      for (const detail of details) {
        if (detail.transaction?.type !== Transaction_Type.REDEEM) {
          continue;
        }

        if (!detail.store) {
          continue;
        }

        const redemptionId = detail.transaction.redemptionId;
        const date = detail.transaction.createdAt;
        const amount = Number(detail.transaction.amount);

        if (!redemptionId || !date) {
          throw new Error("invalid redemption");
        }

        result.push({
          id: redemptionId,
          amount,
          dateText: format(date.toDate(), "yyyy/MM/dd"),
          storeId: detail.store.id,
          storeName: detail.store.name,
        });
      }
    }

    return result;
  }, [transactionGroups]);

  const tableData = useMemo((): RedemptionTableData[] => {
    const groupedData = new Map<string, RedemptionTableData>();

    for (const redemption of redemptions) {
      const key = `${redemption.id}_${redemption.storeId}`;

      if (groupedData.has(key)) {
        const existing = groupedData.get(key);
        if (existing) {
          existing.amount += redemption.amount;
        }
      } else {
        groupedData.set(key, {
          redemptionId: redemption.id,
          dateText: redemption.dateText,
          storeId: redemption.storeId,
          storeName: redemption.storeName,
          amount: redemption.amount,
        });
      }
    }

    return Array.from(groupedData.values());
  }, [redemptions]);

  return (
    <div
      className={css({
        px: "40px",
        py: "24px",
        pb: "100px",
        h: "100%",
        overflow: "auto",
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        振込履歴
      </h1>

      <>
        <select
          value={format(selectedDate, "yyyy/MM")}
          onChange={(e) => handleDateChange(e.target.value)}
          className={css({
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "border.secondary",
            marginBottom: "20px",
            width: "200px",
            fontSize: "14px",
            bg: "white",
          })}
        >
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {loading ? (
          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "40px",
              color: "text.secondary",
              flexDirection: "column",
              gap: "8px",
            })}
          >
            <div>読み込み中...</div>
            <div className={css({ fontSize: "14px" })}>
              {loadingProgress} / {totalStores}
            </div>
          </div>
        ) : (
          <>
            <div
              className={css({
                display: "flex",
                flexDir: "column",
                gap: "6px",
                mb: "12px",
                width: "100%",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "2",
                })}
              >
                <div>振込額合計</div>
                <div className={css({ fontSize: "2xl", fontWeight: "bold" })}>
                  {formatNumber(
                    tableData.reduce((sum, data) => sum + data.amount, 0),
                  )}
                  円
                </div>
              </div>
              <div
                className={css({
                  color: "text.secondary",
                  fontSize: "12px",
                })}
              >
                締め処理完了後、所定のスケジュールに従って振込が実行されます。
              </div>
            </div>

            <div
              className={css({
                mt: "12px",
                bg: "white",
                borderRadius: "10px",
                overflow: "hidden",
                border: "1px solid",
                borderColor: "border.secondary",
              })}
            >
              <table
                className={css({
                  width: "100%",
                  borderCollapse: "collapse",
                })}
              >
                <thead>
                  <tr
                    className={css({
                      borderBottom: "1px solid",
                      borderColor: "border.secondary",
                      bg: "white",
                    })}
                  >
                    <th
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "left",
                        fontWeight: "normal",
                        color: "text.secondary",
                      })}
                    >
                      締め処理開始日
                    </th>
                    <th
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "left",
                        fontWeight: "normal",
                        color: "text.secondary",
                      })}
                    >
                      金額
                    </th>
                    <th
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "left",
                        fontWeight: "normal",
                        color: "text.secondary",
                      })}
                    >
                      店舗名
                    </th>
                    <th
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "left",
                        fontWeight: "normal",
                        color: "text.secondary",
                      })}
                    >
                      精算ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data) => (
                    <tr
                      key={`${data.redemptionId}_${data.storeId}`}
                      className={css({
                        borderBottom: "1px solid",
                        borderColor: "border.secondary",
                        _last: {
                          borderBottom: "none",
                        },
                      })}
                    >
                      <td
                        className={css({
                          py: "12px",
                          px: "16px",
                        })}
                      >
                        {data.dateText}
                      </td>
                      <td
                        className={css({
                          py: "12px",
                          px: "16px",
                        })}
                      >
                        {formatNumber(data.amount)}円
                      </td>
                      <td
                        className={css({
                          py: "12px",
                          px: "16px",
                          wordBreak: "break-all",
                          maxWidth: "320px",
                        })}
                      >
                        {data.storeName}
                      </td>
                      <td
                        className={css({
                          py: "12px",
                          px: "16px",
                          fontFamily: "monospace",
                        })}
                      >
                        <Id id={data.redemptionId} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    </div>
  );
}
